<template>
    <div class="page page-news">
        <div class="news-content">
            <div class="news-item" v-for="(item, index) in cpd_news" :key="index">
                <div class="title" @click="$router.push('/news/' + item.id)">{{item.title}}</div>
                <div class="tips">
                    {{item.createTime}}
                </div>
                <div class="body" v-html="item.info" />
            </div>
        </div>
    </div>
</template>

<script>
import { news } from '../../config/news'
export default {
    name: 'News',
    computed: {
        cpd_news() {
            return Object.values(news).reverse()
        }
    }
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-news {
    .news-content {
        width: 800px;
        margin: 24px auto;
        @media @mobile {
            width: 100%;
            padding: 0 16px;
        }
        .news-item {
            .title {
                font-size: 18px;
                padding-bottom: 12px;
                cursor: pointer;
                font-family: GothamBold;
                &:hover {
                    text-decoration: underline;
                }
            }
            .tips {
                color: #3F3F3F;
                line-height: 28px;
            }
            .body {
                color: #999;
            }
            padding-bottom: 36px;
        }
    }
}
</style>
